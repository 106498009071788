#facets-modal-open-button {
  position: fixed;
  bottom: 0;
  z-index: 1;
  right: 0;
  margin: 1rem;
  border-radius: 2rem;
  padding: 1rem;
  background: #057c5a;
}
#facets-modal-open-button svg {
  margin: 0;
  fill: white !important;
}
