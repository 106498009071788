/*-------------------
  Font Colors
--------------------*/
/*-------------------
  Background Colors
--------------------*/
.documentFirstHeading::before {
  border-bottom: 3px solid #364272 !important;
}
body {
  min-width: 400px !important;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div {
  position: relative;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div img {
  width: 100%;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div > p {
  border-bottom: 6px solid #507196;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px !important;
}
.nnsa-homepage-image-block a,
.nnsa-homepage-image-block a:hover {
  color: #ffffff;
}
#nnsa-second-block .ui.button,
#nnsa-second-block .accordion-title {
  color: #ffffff !important;
  background: #507196 !important;
  border-radius: 5px !important;
}
#nnsa-homepage-newspanel .row,
#nnsa-homepage-newspanel h2,
#nnsa-homepage-newspanel .items {
  margin: 0;
  padding: 0;
}
.block.search .facets {
  margin-top: 1.5em;
}
.block.search .facets .segment.facet {
  padding: 0;
  border: 0.5px solid #c7d5d8;
  padding: 8px !important;
  border-radius: 5px;
}
.margin-auto {
  margin: auto;
}
.no-ul-marker ul {
  list-style-type: none;
}
.objectbrowser-field .item-title {
  white-space: unset !important;
}
#tabs .menu .item {
  text-transform: capitalize;
}
#tabs .menu .item.active {
  font-weight: bolder;
  border-bottom: 5px solid;
}
#tabs .tab {
  border-left: 5px solid;
  border-left-color: #057c5a;
}
#tabs .tab.archive {
  border-left-color: #e40101;
}
#TSPTypeNNSA {
  background-color: white;
}
#TSPTypeNNSA .main-content .row {
  margin-bottom: 10px;
}
#TSPTypeNNSA .status {
  height: 19px;
  padding: 12px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5em;
  height: auto;
  background-color: #E30B00;
}
#TSPTypeNNSA .status.archive {
  background-color: #e40101;
}
#TSPTypeNNSA .status.current {
  background-color: #057c5a;
}
#TSPTypeNNSA .title {
  background-color: white;
  line-height: 125%;
  text-transform: none;
  color: #03324C;
  font-weight: 200;
  letter-spacing: normal;
  font-size: 25px;
  margin: 0;
}
#show-graph {
  color: white;
  font-weight: 400;
}
#show-graph.archive {
  background-color: #e40101;
}
#show-graph.current {
  background-color: #057c5a;
}
.documentgraph {
  font-family: 'Rajdhani', sans-serif;
  background: white;
  cursor: all-scroll;
  border-radius: 10px;
  box-shadow: 0px 0px 15px #000;
}
.documentgraph .nodes {
  fill: darkgray;
  cursor: pointer;
  stroke-width: 1px;
  stroke: #333;
}
.documentgraph .nodes .node rect {
  rx: 5;
  ry: 5;
}
.documentgraph .nodes .node.current-node {
  fill: #f1b859;
}
.documentgraph .nodes .label div {
  color: black;
  font-size: 100%;
  font-weight: 600;
}
.documentgraph .edgeLabels tspan {
  font-size: larger;
}
.documentgraph path {
  stroke: black;
  fill: black;
  stroke-width: 1.5px;
}
.documentgraph .edgeLabel {
  filter: url(#svgbg);
}
.documentgraph .svgbg {
  position: fixed;
  height: 0;
  width: 0;
}
#document-metadata table {
  border: none;
}
#document-metadata table tr td {
  border-bottom: 0.5px solid #d0d0d0c7;
}
#document-metadata table tr td:first-child {
  background-color: #ddd;
  font-weight: 500;
}
