/*-------------------
  Font Colors
--------------------*/
/*-------------------
  Background Colors
--------------------*/
.documentFirstHeading::before {
  border-bottom: 3px solid #364272 !important;
}
body {
  min-width: 400px !important;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div {
  position: relative;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div img {
  width: 100%;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div > p {
  border-bottom: 6px solid #507196;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px !important;
}
.nnsa-homepage-image-block a,
.nnsa-homepage-image-block a:hover {
  color: #ffffff;
}
#nnsa-second-block .ui.button,
#nnsa-second-block .accordion-title {
  color: #ffffff !important;
  background: #507196 !important;
  border-radius: 5px !important;
}
#nnsa-homepage-newspanel .row,
#nnsa-homepage-newspanel h2,
#nnsa-homepage-newspanel .items {
  margin: 0;
  padding: 0;
}
.block.search .facets {
  margin-top: 1.5em;
}
.block.search .facets .segment.facet {
  padding: 0;
  border: 0.5px solid #c7d5d8;
  padding: 8px !important;
  border-radius: 5px;
}
.margin-auto {
  margin: auto;
}
.no-ul-marker ul {
  list-style-type: none;
}
.objectbrowser-field .item-title {
  white-space: unset !important;
}
#footer-wrapper {
  background: #242424;
  color: #ffffff;
  /* 
    a:hover {
        textDecoration: "underline";
    } */
}
#footer-wrapper #footer-mobile {
  background: grey;
}
#footer-wrapper .segment-1,
#footer-wrapper .segment-2 {
  border: none;
  padding: 20px 0px;
  border: 0px;
  margin: 0px;
}
#footer-wrapper .DropDown {
  background: blue;
  color: white;
  font-size: 25px;
}
#footer-wrapper #DropDownItems {
  margin-left: 50px !important;
}
#footer-wrapper .DropDownItems {
  margin-left: 50px !important;
}
@media screen and (max-width: 767px) {
  #footer-wrapper .list {
    flex-direction: column;
  }
}
#footer-wrapper .list .item {
  margin: 0px;
}
#footer-wrapper .footer {
  display: flex !important;
  justify-content: space-between;
  text-align: left;
}
#footer-wrapper .footer .header {
  height: 5rem;
  display: table-cell;
  vertical-align: bottom;
  color: inherit;
}
@media screen and (max-width: 767px) {
  #footer-wrapper .footer .header {
    height: 0;
  }
}
#footer-wrapper .footer .content {
  margin-top: 3rem;
}
#footer-wrapper .footer .content .item {
  margin-bottom: 15px;
}
#footer-wrapper .footer .content .doeaddress {
  display: grid;
  line-height: 2;
}
@media screen and (max-width: 767px) {
  #footer-wrapper .footer .content {
    padding: 2rem;
    margin: 0px;
  }
}
#footer-wrapper .footer.mobile .item:not(:first-child) .header {
  display: flex;
  padding: 2rem;
}
#footer-wrapper .footer.mobile .item:not(:first-child) .header::after {
  content: "\276F";
  width: 50px;
}
#footer-wrapper .segment-2 {
  background: #000;
}
#footer-wrapper .segment-2 .list {
  display: flex !important;
  justify-content: space-between;
}
#footer-wrapper .segment-2 .list .item {
  display: flex;
  margin-bottom: 1rem;
}
#footer-wrapper .segment-2 .list .item::before {
  content: '\00B7';
  font-size: 3rem;
  margin-right: 5px;
}
#footer-wrapper .segment-2 .list .item a {
  color: white;
}
