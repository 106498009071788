@font-face {
    font-family: 'Karla';
    src: url(/static/media/Karla-VariableFont_wght.27ff3204.ttf) format('truetype');
    font-weight: 1 1000;
    font-style: normal;
}

@font-face {
    font-family: 'Karla';
    src: url(/static/media/Karla-Italic-VariableFont_wght.1799411f.ttf) format('truetype');
    font-weight: 1 1000;
    font-style: italic;
}