/*-------------------
  Font Colors
--------------------*/
/*-------------------
  Background Colors
--------------------*/
.documentFirstHeading::before {
  border-bottom: 3px solid #364272 !important;
}
body {
  min-width: 400px !important;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div {
  position: relative;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div img {
  width: 100%;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div > p {
  border-bottom: 6px solid #507196;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px !important;
}
.nnsa-homepage-image-block a,
.nnsa-homepage-image-block a:hover {
  color: #ffffff;
}
#nnsa-second-block .ui.button,
#nnsa-second-block .accordion-title {
  color: #ffffff !important;
  background: #507196 !important;
  border-radius: 5px !important;
}
#nnsa-homepage-newspanel .row,
#nnsa-homepage-newspanel h2,
#nnsa-homepage-newspanel .items {
  margin: 0;
  padding: 0;
}
.block.search .facets {
  margin-top: 1.5em;
}
.block.search .facets .segment.facet {
  padding: 0;
  border: 0.5px solid #c7d5d8;
  padding: 8px !important;
  border-radius: 5px;
}
.margin-auto {
  margin: auto;
}
.no-ul-marker ul {
  list-style-type: none;
}
.objectbrowser-field .item-title {
  white-space: unset !important;
}
#standards-header {
  margin: 0px;
  padding: 0px;
}
#standards-header a {
  color: white;
}
#standards-header .header-1 {
  background: hsl(216, 13%, 84%);
  padding: 1rem 2rem;
}
#standards-header .header-1 .mobile-only {
  display: none;
}
#standards-header .header-1 .grid {
  margin: 0;
}
#standards-header .header-1 .grid .row {
  align-items: center;
}
#standards-header .header-2 {
  display: flex;
  width: 100%;
  padding: 0.5rem 3rem;
  align-items: center;
  justify-content: space-between;
  background: #364272;
}
#standards-header .header-2 #navigation .menu {
  margin: 0;
  flex-wrap: wrap;
}
#standards-header .header-2 #navigation .item {
  padding: 0.8rem 0;
  margin: 0.5rem 1rem;
}
@media screen and (max-width: 992px) {
  #standards-header .header-2 {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  #standards-header .header-1 {
    height: 12.7rem;
    padding: 0;
  }
  #standards-header .header-1 .row {
    justify-content: space-around;
  }
  #standards-header .header-1 .row > .div {
    display: flex;
  }
  #standards-header .header-1 .block-menu {
    order: 1;
    padding-inline: 0;
    display: flex;
  }
  #standards-header .header-1 .block-menu button {
    padding-inline: 0;
  }
  #standards-header .header-1 .block-logo {
    padding: 21px 11px;
    order: 2;
    transform: none;
    display: flex;
    justify-content: center;
  }
  #standards-header .header-1 .block-logo img {
    width: 210px;
  }
  #standards-header .header-1 .mobile-only {
    order: 3;
    display: flex;
    align-items: center;
  }
  #standards-header .header-1 .block-search {
    order: 4;
    flex-basis: 100%;
    padding: 0;
  }
  #standards-header .header-1 .ui.image {
    max-width: unset;
  }
}
#header-side-menu {
  width: clamp(10rem, calc(20rem + 5vw), 1000px);
}
@media screen and (max-width: 767px) {
  #header-side-menu {
    height: 100%;
    width: 100%;
  }
}
.actions .segment {
  all: unset;
}
#header-side-menu .only-mobile-links {
  display: none;
}
@media screen and (max-width: 992px) {
  #header-side-menu .only-mobile-links {
    display: block;
  }
}
