/*-------------------
  Font Colors
--------------------*/
/*-------------------
  Background Colors
--------------------*/
.documentFirstHeading::before {
  border-bottom: 3px solid #364272 !important;
}
body {
  min-width: 400px !important;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div {
  position: relative;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div img {
  width: 100%;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div > p {
  border-bottom: 6px solid #507196;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px !important;
}
.nnsa-homepage-image-block a,
.nnsa-homepage-image-block a:hover {
  color: #ffffff;
}
#nnsa-second-block .ui.button,
#nnsa-second-block .accordion-title {
  color: #ffffff !important;
  background: #507196 !important;
  border-radius: 5px !important;
}
#nnsa-homepage-newspanel .row,
#nnsa-homepage-newspanel h2,
#nnsa-homepage-newspanel .items {
  margin: 0;
  padding: 0;
}
.block.search .facets {
  margin-top: 1.5em;
}
.block.search .facets .segment.facet {
  padding: 0;
  border: 0.5px solid #c7d5d8;
  padding: 8px !important;
  border-radius: 5px;
}
.margin-auto {
  margin: auto;
}
.no-ul-marker ul {
  list-style-type: none;
}
.objectbrowser-field .item-title {
  white-space: unset !important;
}
#news-view-1 a {
  font-size: inherit;
}
#news-view-1 div {
  margin-bottom: 1.5rem;
}
#news-view-1 span,
#news-view-1 #more-link {
  float: inline-end;
  font-size: 0.88em;
}
