/*-------------------
  Font Colors
--------------------*/
/*-------------------
  Background Colors
--------------------*/
.documentFirstHeading::before {
  border-bottom: 3px solid #364272 !important;
}
body {
  min-width: 400px !important;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div {
  position: relative;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div img {
  width: 100%;
}
.nnsa-homepage-image-block > div.columns-view > .column-grid > div > div > p {
  border-bottom: 6px solid #507196;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px !important;
}
.nnsa-homepage-image-block a,
.nnsa-homepage-image-block a:hover {
  color: #ffffff;
}
#nnsa-second-block .ui.button,
#nnsa-second-block .accordion-title {
  color: #ffffff !important;
  background: #507196 !important;
  border-radius: 5px !important;
}
#nnsa-homepage-newspanel .row,
#nnsa-homepage-newspanel h2,
#nnsa-homepage-newspanel .items {
  margin: 0;
  padding: 0;
}
.block.search .facets {
  margin-top: 1.5em;
}
.block.search .facets .segment.facet {
  padding: 0;
  border: 0.5px solid #c7d5d8;
  padding: 8px !important;
  border-radius: 5px;
}
.margin-auto {
  margin: auto;
}
.no-ul-marker ul {
  list-style-type: none;
}
.objectbrowser-field .item-title {
  white-space: unset !important;
}
.content-area {
  display: grid;
}
#SearchAppHeader {
  color: black;
}
#SearchAppHeader input[type="text"] {
  border: none;
  color: black;
  font-weight: 400;
}
#SearchAppHeader input[type="text"]::-webkit-input-placeholder {
  color: black;
}
#SearchAppHeader .fields {
  align-items: center;
  margin: 0;
}
@media screen and (max-width: 992px) {
  #SearchAppHeader .fields .main-search-box {
    padding-inline: 1rem;
  }
}
#SearchAppHeader .field {
  padding: 0;
  font-size: 1.15rem;
  font-weight: 500;
}
#SearchAppHeader .advanced-options {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  color: #364272;
  text-transform: capitalize;
}
#SearchAppHeader .advanced-options span {
  margin-inline: 2px;
}
#SearchAppHeader .advanced-options .nnsa {
  text-transform: uppercase;
}
#SearchAppHeader .advanced-options .active {
  color: #ffffff;
  background-color: #364272;
}
#SearchAppHeader .advanced-options .options {
  display: flex;
  gap: 0.4rem;
}
@media screen and (max-width: 767px) {
  #SearchAppHeader .advanced-options .options {
    flex-flow: column;
  }
}
#SearchAppHeader .advanced-options .icon {
  font-size: 2rem !important;
  display: flex;
  align-items: center;
}
#SearchAppHeader .advanced-options > .menu {
  margin-top: 0.3em;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid grey;
  border-radius: 0px 0px 5px 5px;
}
#SearchAppHeader .advanced-options > .menu .divider {
  margin-bottom: 0.5rem;
}
#SearchAppHeader .advanced-options > .menu .item {
  padding: 0.35em !important;
}
#SearchAppHeader .advanced-options > .menu .item input,
#SearchAppHeader .advanced-options > .menu .item textarea {
  color: black;
  padding: 5px 10px;
}
#SearchAppHeader .advanced-options > .menu .item input::-webkit-input-placeholder {
  font-weight: 400;
  color: black;
}
#SearchAppHeader .advanced-options > .menu .item textarea::-webkit-input-placeholder {
  font-weight: 400;
  color: black;
}
#SearchAppHeader .advanced-options > .menu .item::after {
  content: '';
}
#SearchAppHeader .advanced-options > .menu .item:hover {
  background: none !important;
}
#SearchAppHeader .advanced-options > .menu button {
  padding: 0.8rem 0.8rem;
}
#SearchAppHeader .advanced-options > .menu .button-submit {
  width: 100%;
  margin-top: 5px;
  border-radius: 0 0 5px 5px;
}
#SearchAppHeader .advanced-options > .menu input[type="text"] {
  border: none;
  color: black;
  font-weight: 400;
}
#SearchAppHeader .advanced-options > .menu input[type="text"]::-webkit-input-placeholder {
  color: black;
}
#SearchAppHeader .advanced-options .text {
  text-transform: capitalize;
  border-left: 2px solid black;
  padding-left: 0.5rem;
}
#SearchAppHeader .advanced-options textarea {
  border: 1px solid #c7d5d8;
  border-radius: 5px;
}
#SearchAppHeader .advanced-options .daterange {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#SearchAppHeader .advanced-options .daterange input {
  border: none;
}
#SearchAppHeader .advanced-search {
  text-align: center;
  margin-inline: 10px;
  height: 100%;
}
#SearchAppHeader .advanced-search button {
  height: 100%;
  text-align: center;
  line-height: 1.8rem;
  color: #364272 !important;
  font-weight: 500;
}
@media screen and (max-width: 992px) {
  #SearchAppHeader .advanced-search {
    display: none;
  }
}
#SearchAppHeader .search-icon-btn {
  height: 100%;
  display: flex;
  justify-content: center;
}
